import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Hello there! I'm`}</h2>
    <h1>{`Kenneth Wang`}</h1>
    <p>{`I'm a `}<strong parentName="p">{`designer`}</strong>{`, `}<strong parentName="p">{`developer`}</strong>{`, and `}<strong parentName="p">{`project manager`}</strong>{` currently based in San Francisco. I have a passion for `}<strong parentName="p">{`elegant design`}</strong>{` and `}<strong parentName="p">{`optimized solutions`}</strong>{`.`}</p>
    <p>{`I love `}<strong parentName="p">{`making things`}</strong>{`, and I enjoy using my obsessive attention to detail to create products that make the world a better place.`}</p>
    <hr></hr>
    <p>{`I have a very broad range of skills, from `}<strong parentName="p">{`UI/UX design`}</strong>{` to `}<strong parentName="p">{`fullstack development`}</strong>{` to `}<strong parentName="p">{`DevOps`}</strong>{`. I take pride in my ability to design, develop, and deploy web solutions single-handedly.`}</p>
    <p>{`I'm obsessed with `}<strong parentName="p">{`typography`}</strong>{`. Text is ubiquitious, and I believe typography to be the most important topic in design. Typography on the `}<strong parentName="p">{`web`}</strong>{` is still relatively new, and I hope to become a leading authority figure in that field.`}</p>
    <hr></hr>
    <p>{`Outside of work, I enjoy `}<strong parentName="p">{`game development`}</strong>{` and `}<strong parentName="p">{`digital painting`}</strong>{`.`}</p>
    <p>{`My current focus is on developing highly maintainable, modern, `}<strong parentName="p">{`typography libraries`}</strong>{` using TypeScript and Node.js. I will `}<strong parentName="p">{`not be available`}</strong>{` for contractual work until Q2 2020.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      